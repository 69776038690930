import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const AboutPage = () => (
  <Layout>
    <SEO title="About" />

    <section>
      <div className="container">
        <h1 className="section-heading">Thank you</h1>
        <div className="alert alert-success mt-4 text-center">
          Your message has been sent to our email. We will be in touch soon.
        </div>
      </div>
    </section>
  </Layout>
);

export default AboutPage;
